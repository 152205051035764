import axios from "axios";

export default class Users {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
    // this.API_LINK = 'http://localhost:8014'
  }

  async getFromArray(queries) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/users/getFromArray`,
        data: queries
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getUsers(queries) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/users/searchAndFilter`,
        data: queries
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async getUsersExtern(queries,page,limit) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/diagnosis/admin/users?limit=${limit}&offset=${page}`,
        data: {
          "action":"list",
          "filters":queries
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async getUser(controlNumber) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/users/${controlNumber}`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async saveUser(controlNumber, data) {
    const url = `${this.API_LINK}/users/${controlNumber ? controlNumber : ""}`;
    try {
      const response = await axios({
        method: controlNumber ? "patch" : "post",
        url: url,
        data: data
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async uploadUsers(bucket, key, userType) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/users/sync`,
        data: {
          srcBucket: bucket,
          src: key,
          userType: userType
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async statusInterval(url, userType) {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        try {
          const resp = await this.getUploadStatus(url, userType);
          if (resp.data.status == true) {
            resolve(resp);
            clearInterval(interval);
          }
        } catch (error) {
          reject(error);
        }
      }, 30000);
    });
    // const resp = await this.getUploadStatus();
    // if (resp.data.status != true) await this.statusInterval();
    // else return resp;
  }
  async getUploadStatus(url, userType) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}${url}?userType=${userType}`
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async uploadChart(url, bucket, key) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}${url}`,
        data: {
          srcBucket: bucket,
          src: key
        }
      });
      if (response.data.statusCode == 502) throw response;
      return response;
    } catch (error) {
      if (error.data && error.data.statusCode == 502) throw error.data;
      try {
        await this.statusInterval(url);
      } catch (error) {
        throw error;
      }
    }
  }

  async getAdminUsers() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/admin/admin`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async downloadUserReport(userType) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/users/export/users?userType=${userType}`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
